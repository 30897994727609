import { consumeService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { ChargeUnitEnum } from '@/resource/enum';
import { ProductBrokenResource } from '@/resource/model';
import { mathRound6, messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class EditConsume extends mixins(DialogMixin) {
  @Prop({ type: Number, required: true })
  public id!: number;

  /**
   * 耗损上报数据
   */
  public formData: ProductBrokenResource | null = null;

  public formRules: { [P in keyof Partial<ProductBrokenResource>]: Array<Object> } = {
    fallbackOperation: [
      {
        required: true,
        validator: (rule: Object, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('productBroken.selectBrokenProcess')));
            return;
          }

          callback();
        }
      }
    ],
    attritionCount: [
      {
        required: true,
        validator: (rule: Object, value: number, callback: Function): void => {
          if (!value || value === 0) {
            callback(new Error(translation('productBroken.inputCount')));
            return;
          }
          callback();
        }
      }
    ],
    calcUnit: [
      {
        required: true,
        validator: (rule: Object, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('productBroken.selectUnit')));
            return;
          }

          callback();
        }
      }
    ],
    dutyReason: [
      {
        required: true,
        validator: (rule: Object, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('productBroken.inputDutyReason')));
            return;
          }

          callback();
        }
      }
    ],
    dutyUser: [
      {
        required: true,
        validator: (rule: Object, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('productBroken.inputDutyUser')));
            return;
          }

          callback();
        }
      }
    ]
  };

  public get isShowCountInput(): boolean {
    return this.formData!.calcUnit !== ChargeUnitEnum.squareMeter;
  }

  public get attritionArea(): string {
    return `${translation('productBroken.brokenArea')}：${this.formData?.attritionArea?.toFixed(6) ?? 0}㎡`;
  }

  public created(): void {
    this.getConsumeInfo();
  }

  /**
   * 获取单位列表
   */
  public getUnitList(): Array<any> {
    const unitList: Array<any> = [];
    for (const key in ChargeUnitEnum) {
      if (!isNaN(Number(key)) && typeof Number(key) === 'number') {
        unitList.push({
          label: translation(`chargeUnit.${ChargeUnitEnum[key]}`),
          value: Number(key)
        });
      }
    }
    return unitList;
  }

  /**
   * 提交
   */
  public handleSubmit(): void {
    (this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      try {
        this.setLoading(true);
        await consumeService.put(this.formData as ProductBrokenResource);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('consumed-success');
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public getConsumeInfo(): void {
    consumeService
      .getById(this.id)
      .then(res => {
        this.formData = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  public unitChanged(): void {
    if (this.formData!.calcUnit !== ChargeUnitEnum.squareMeter) {
      this.formData!.attritionArea = this.defaultAreaForNotSquareMeter();
      this.formData!.attritionCount = 1;
    }
  }

  public calcAttritionArea(): void {
    this.formData!.attritionArea = mathRound6(
      this.formData!.attritionCount *
        (this.formData!.finishWidth / 1000) *
        (this.formData!.finishHeight / 1000)
    );
  }

  private defaultAreaForNotSquareMeter(): number {
    return mathRound6((this.formData!.finishWidth / 1000) * (this.formData!.finishHeight / 1000) * 1);
  }
}
