import { render, staticRenderFns } from "./edit-consume.vue?vue&type=template&id=40d1baac&scoped=true&"
import script from "./edit-consume.ts?vue&type=script&lang=ts&"
export * from "./edit-consume.ts?vue&type=script&lang=ts&"
import style0 from "./edit-consume.scoped.scss?vue&type=style&index=0&id=40d1baac&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d1baac",
  null
  
)

export default component.exports