import { consumeService, DataDictionaryService } from '@/api';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { ChargeUnitEnum, DutyAgencyTypeEnum } from '@/resource/enum';
import { ConsumeItemList, ConsumeItemListQuery } from '@/resource/model';
import { dateFormat, downloadFileByBlob, messageError, showWarningConfirm, translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import EditConsume from './edit-consume/edit-consume.vue';
import ConsumeInfo from './consume-info/consume-info.vue';
import { Message } from 'element-ui';
import { NormalSelectOptions } from '@/resource/model/common';

@Component({
  components: { EditConsume, ConsumeInfo }
})
export default class ConsumeManagement extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<ConsumeItemList> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<ConsumeItemList>> = [
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'customerName',
      label: 'projectProduct.customerName',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'productName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '250px'
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionCount',
      label: 'brokenLog.count',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionArea',
      label: 'productBroken.brokenArea',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'calcUnit',
      label: 'brokenLog.priceUnit',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ConsumeItemList).calcUnit) {
          return '--';
        }
        return translation(`chargeUnit.${ChargeUnitEnum[(row as ConsumeItemList).calcUnit]}`);
      }
    },
    {
      prop: 'dutyUser',
      label: 'brokenLog.dutyUser',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'dutyReason',
      label: 'productBroken.dutyReason',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionLinkName',
      label: 'consume.consumeStage',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionTypeName',
      label: 'consume.consumeType',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'dutyOrganizeType',
      label: 'consume.dutyAgencyType',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ConsumeItemList).dutyOrganizeType) {
          return '--';
        }
        return translation(`dutyAgencyType.${DutyAgencyTypeEnum[(row as ConsumeItemList).dutyOrganizeType]}`);
      }
    },
    {
      prop: 'createUserName',
      label: 'consume.createUserName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'consume.createTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ConsumeItemList).createTime, 'YYYY-MM-DD HH:mm');
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      plain: true,
      permissionCode: 'production:attrition:exportData',
      handleClick: this.export
    }
  ];

  public rowOperationOptions: RowOperation<ConsumeItemList> = {
    fixed: 'right',
    width: '120px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'production:attrition:edit',
        handleClick: (row: ConsumeItemList): void => {
          this.openEditDialog(row);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'production:attrition:delete',
        handleClick: this.deleteConsume
      }
    ]
  };

  public queryItemsOption: Array<OsQueryItemOption<ConsumeItemListQuery>> = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'projectProduct.itemCode',
      option: {
        placeholder: 'consume.inputItemCode'
      }
    },
    {
      type: 'Input',
      field: 'productName',
      label: 'projectProduct.platformProduct',
      option: {
        placeholder: 'consume.inputPlatformProduct'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'order.createDate',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): Boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    },
    {
      type: 'Input',
      field: 'dutyUser',
      label: 'consume.dutyUser',
      option: {
        placeholder: 'consume.inputDutyUser'
      }
    },
    {
      type: 'Select',
      field: 'attritionLinks',
      label: 'consume.consumeStage',
      option: {
        placeholder: 'consume.selectConsumeStage',
        filterable: true,
        multiple: true
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'dutyOrganizeTypes',
      label: 'consume.dutyAgencyType',
      option: {
        placeholder: 'consume.selectDutyAgencyType',
        filterable: true,
        multiple: true
      },
      optionData: this.dutyAgencyType
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'projectProduct.customerName',
      option: {
        placeholder: 'projectProduct.customerName',
        filterable: true
      },
      optionData: []
    }
  ];

  public queryForm: Partial<ConsumeItemListQuery> = {};

  public consumeInfo: ConsumeItemList | null = null;

  public infoVisible = false;

  /**
   * 控制编辑dialog
   */
  public editVisible = false;

  public editId = 0;

  /**
   * 项次的总数量
   */
  public itemCount = 0;

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'consume-list');
    this.loadData();
    this.getConsumeStages();
    this.getcustomerNameList();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public linkToDetails(consumeInfo: ConsumeItemList): void {
    this.consumeInfo = consumeInfo;
    this.infoVisible = true;
  }

  public async deleteConsume(consumeInfo: ConsumeItemList): Promise<void> {
    try {
      await showWarningConfirm(translation('tip.confirmDelete'));
      await consumeService.delete(consumeInfo.id);
      Message.success(translation('operationRes.deleteSuccess'));
      this.loadData();
    } catch (error) {
      if (error !== 'cancel') messageError(error);
    }
  }

  private async loadData(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const res = await consumeService.getList(this.queryForm, this.paging);
      this.tableOption.data = res.data || [];
      this.totalData = res.total || 0;
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const blob = await consumeService.export(this.queryForm);
      downloadFileByBlob(`${translation('consume.export')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private openEditDialog(row: ConsumeItemList): void {
    this.editId = row.id;
    this.itemCount = row.count;
    this.editVisible = true;
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  private async getConsumeStages(): Promise<void> {
    try {
      const stagesQueryIndex = this.queryItemsOption.findIndex(x => x.field === 'attritionLinks');
      this.queryItemsOption[stagesQueryIndex].optionData = (
        await DataDictionaryService.getConsumeStage()
      ).map(x => {
        return {
          label: x.value,
          value: x.code
        };
      });
    } catch (error) {
      messageError(error);
    }
  }
  private async getcustomerNameList(): Promise<void> {
    try {
      const stagesQueryIndex = this.queryItemsOption.findIndex(x => x.field === 'customerId');
      this.queryItemsOption[stagesQueryIndex].optionData = (await consumeService.getcustomerNameList()).map(
        x => {
          return {
            label: x.customerName,
            value: x.customerId
          };
        }
      );
    } catch (error) {
      messageError(error);
    }
  }

  private dutyAgencyType(): NormalSelectOptions {
    return consumeService.getDutyAgencyTypes().map(x => {
      x.label = translation(x.label);
      return x;
    });
  }
}
