import { DialogMixin } from '@/mixins/dialog';
import { ChargeUnitEnum, DutyAgencyTypeEnum } from '@/resource/enum';
import { ConsumeItemList } from '@/resource/model';
import { translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ConsumeInfo extends mixins(DialogMixin) {
  @Prop({ type: Object, required: true })
  public consume!: ConsumeItemList;

  public get calcUnit(): string {
    return translation(`chargeUnit.${ChargeUnitEnum[this.consume.calcUnit]}`);
  }

  public get dutyAgencyType(): string {
    if (!this.consume.dutyOrganizeType) {
      return '';
    }
    return translation(`dutyAgencyType.${DutyAgencyTypeEnum[this.consume.dutyOrganizeType]}`);
  }
}
